import '../styles/application.scss';

require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();

require.context('../images', true);

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

document.addEventListener('turbolinks:load', () => {
  const $header = document.querySelector('header');
  const $newsletterForm = document.querySelector('.newsletter-form');

  if ($header) {
    const $toggler = $header.querySelector('.toggler');

    $toggler.addEventListener('click', () => {
      $header.classList.toggle('--active');
    });
  }

  if ($newsletterForm) {
    const $email = $newsletterForm.querySelector('input[type="email"]');
    const $submit = $newsletterForm.querySelector('input[type="submit"]');
    const $success = document.createElement('span');

    $success.innerText = $newsletterForm.dataset.success;

    $newsletterForm.addEventListener('submit', () => {
      window.open($newsletterForm.action, 'popupwindow', 'scrollbars=yes,width=800,height=600');

      $email.readOnly = true;
      $submit.disabled = true;

      $newsletterForm.appendChild($success);
    });
  }
});

document.addEventListener('turbolinks:before-cache', () => {
  const $header = document.querySelector('header');

  if ($header) {
    $header.classList.remove('--active');
  }
});
